import React from 'react'
import { SoftwareView } from '@views'

import { graphql } from 'gatsby'

import { Seo } from '@atoms'

export const Head = () => {
  return <Seo title={'Software'} />
}

const Software = (props) => {
  const data = props?.data?.contentfulCaseStudyGrid

  return <SoftwareView data={data} />
}

export const softwareQuery = graphql`
  query softwareQuery {
    contentfulCaseStudyGrid(page: { eq: "Software Dev" }) {
      ...CaseStudyGrid
    }
  }
`

export default Software
